// src/components/Banner/Banner.scss

// Importation des variables globales
@import '../../styles/main.scss';

.banner {
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 33%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;

  // Calque semi-transparent
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: inherit;
  }

  .banner-title {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    text-align: left;
    z-index: 2;

    // Styles pour mobile
    font-size: 24px;
    line-height: 100%; // 24px

    @media only screen and (min-width: 360px) {
      max-width: 230px;
    }

    @media only screen and (min-width: 601px) {
      font-size: 32px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      line-height: 142.6%;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: calc(100% - 32px);
      max-width: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media only screen and (min-width: 992px) {
      // Styles pour desktop
      font-size: 40px;
    }

    @media only screen and (min-width: 1280px) {
      // Styles pour desktop
      font-size: 48px;
    }
  }

  // Media queries pour adapter le padding-top et le border-radius
  @media only screen and (min-width: 480px) {
    padding-top: 28%;
  }

  @media only screen and (min-width: 768px) {
    padding-top: 22%;
    border-radius: 15px;
  }

  @media only screen and (min-width: 992px) {
    padding-top: 20%;
    border-radius: 20px;
  }

  @media only screen and (min-width: 1024px) {
    padding-top: 22%;
    border-radius: 25px;
  }

  @media only screen and (min-width: 1280px) {
    padding-top: 18%;
  }
}
