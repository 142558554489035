// src/components/Slideshow/Slideshow.scss

// Importation des variables globales
@import '../../styles/main.scss';

.slideshow {
  position: relative;
  width: 100%;
  height: 255px;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 8px;
    margin-top: -20px;
    color: white;
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease;
    user-select: none;
    border-style: none;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .prev {
    left: 0;
    border-radius: 0 10px 10px 0;
  }

  .next {
    right: 0;
    border-radius: 10px 0 0 10px;
  }

  .prev:hover,
  .next:hover {
    background-color: rgb(255, 96, 96, 0.5);
  }

  .counter {
    display: none;
  }

  /* Styles pour dispositifs autour de 1024px */
  @media only screen and (min-width: 1024px) {
    height: 415px;
    border-radius: 25px;

    .prev,
    .next {
      padding: 16px;
      font-size: 80px;
      margin-top: -56px;
    }

    .counter {
      display: inline;
      position: absolute;
      bottom: 24px;
      right: 50%;
      color: white;
      font-size: 18px;
      background-color: rgba(0, 0, 0, 0.2);
      padding: 2px 8px;
      border-radius: 3px;
    }
  }
}
