// src/styles/main.scss

// Variables
$primary-color: #ff6060;
$background-footer: #000;
$font-color-footer: #fff;
$font-family: 'Montserrat', sans-serif;
$font-size-mobile: 12px;
$font-size-desktop: 18px;

// Global styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  background-color: #fff;
  font-size: $font-size-mobile;
}

// Media query pour les desktops
@media (min-width: 768px) {
  body {
    font-size: $font-size-desktop;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

// Page 404

.error404 {
  display: flex;
  flex-direction: column;
  gap: 113px;
  text-align: center;
  padding: 113px 57px;

  h1 {
    font-size: 96px;
    font-weight: 700;
    color: $primary-color;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 142.6%;
    color: $primary-color;
  }

  a {
    font-size: 14px;
    font-weight: 500;
    line-height: 142.6%;
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    gap: 139px;
    text-align: center;
    padding: 139px 57px;

    h1 {
      font-size: 288px;
    }

    p {
      font-size: 36px;
    }
  }
}

// Style général de la page logement

.housing-sheet {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .opened-collapse {
      .fa-2xl {
        font-size: 1.1em;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    gap: 30px;
    .opened-collapse {
      h2 {
        font-size: 18px;
      }
      .fa-2xl {
        font-size: 1.5em;
      }
    }
  }
}

.housing-info {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
  }
}

.housing-details {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    font-size: 18px;
    font-weight: 500;
    color: $primary-color;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (min-width: 1024px) {
    gap: 15px;

    h1 {
      font-size: 36px;
      font-weight: 500;
    }

    p {
      font-size: 18px;
    }
  }
}

.host-ratings {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 1024px) {
    flex-direction: column-reverse;
    gap: 21px;
    justify-content: center;
  }
}

.housing-collapses {
  display: flex;
  flex-direction: column;
  gap: 20px;

  li {
    list-style: none;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 76px;

    & > div {
      flex: 1;
    }
  }
}
